<!-- 通讯录管理 -->
<template>
  <div style="height:100%;">
    <div
      style="background: rgba(255,255,255,1);padding: 20px;"
      class="flex flex-justify-between flex-align-center"
    >
      <div style="font-size:18px;font-weight:bold;color:rgba(41,41,41,1);">
        职务管理
      </div>
    </div>
    <div class="wrap flex">
      <div class="flex-1"><el-button type="primary" @click="add">新建职务</el-button></div>
      <div class="input-block flex">
        <el-input v-model="searchVal" placeholder="职务名称" prefix-icon="el-icon-search"/>
        <el-button @click="search">搜索</el-button>
      </div>
    </div>
    <div
      class="block"
      style="padding: 10px 20px 0 20px;height: calc(100% - 206px);"
    >
      <div style="height: calc(100% - 60px);overflow-y: auto">
        <el-table :data="roles" style="width: 100%;height:100%" stripe>
          <el-table-column
            prop="name"
            label="职务名称"
            width="100"
          ></el-table-column>
          <el-table-column prop="n_users" label="人数"></el-table-column>
          <el-table-column label="操作" width="180px">
            <template slot-scope="scope">
              <a @click="edit(scope.row)" class="operate-btn">编辑</a>
              <a @click="showDeleteDialog(scope.row.id)" class="operate-btn"
                >删除</a
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="wrap">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="queryOpt.page"
        :page-size="queryOpt.pagesize"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog
      width="500px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <RoleEdit :roleid="curRoleId" @onSuccess="onSaved"></RoleEdit>
    </el-dialog>
    <!-- 删除弹窗 -->
    <el-dialog
      width="500px"
      title="温馨提示"
      :visible.sync="deleteDialogVisible"
      :destroy-on-close="true"
      center
    >
      <div class="dialog-content">确认删除吗？</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="del()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import {} from "@/service/company";
import { getRolesByCompId, delRole } from "@/service/account";
import RoleEdit from "./RoleEdit";
export default {
  components: {
    RoleEdit
  },
  data() {
    return {
      deleteDialogVisible: false,
      popVisible: false,
      popTitle: "",
      queryOpt: {
        pagesize: 15,
        page: 1,
        comp_id: this.$store.state.loginUser.comp.id,
        k:null,
      },
      roles: [],
      total: 0,
      curRoleId: null,
      searchVal:null,
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true
    }
  },
  created() {
    this.debounceRefreshItems = _.debounce(this.reloadRoles, 500);
  },
  mounted() {
    this.reloadRoles();
  },
  methods: {
    search() {
      // console.log(e);
      this.queryOpt.k = this.searchVal;
    },
    reloadRoles() {
      getRolesByCompId(this.queryOpt)
        .then(rst => {
          this.roles = rst.list;
          this.total = rst.total;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    add() {
      this.popTitle = "新建职位";
      this.curRoleId = null;
      this.popVisible = true;
    },
    edit(row) {
      this.popTitle = "编辑职位";
      this.curRoleId = row.id;
      this.popVisible = true;
    },
    showDeleteDialog(id) {
      this.curRoleId = id;
      this.deleteDialogVisible = true;
    },
    del() {
      delRole(this.$store.state.loginUser.comp.id, this.curRoleId)
        .then(() => {
          this.$message.success("删除成功!");
          this.deleteDialogVisible = false;
          this.reloadRoles();
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    onSaved() {
      this.popVisible = false;
      this.curRoleId = null;
      if (this.popTitle == "新建职位") {
        this.$message.success("新建成功!");
      } else if (this.popTitle == "编辑职位") {
        this.$message.success("保存成功!");
      }
      this.reloadRoles();
    }
  }
};
</script>
<style scoped>
div >>> th {
  color: #4d4d4d;
  font-size: 16px;
}
</style>
<style scoped src="./style.css"></style>
<style scoped lang="less">
.block {
  color: rgba(41, 41, 41, 1);
  background: rgba(255, 255, 255, 1);
  overflow: auto;
  margin: 30px;
  padding: 20px;
}

.input-block {
    .el-input {
        width:270px;
    }
    .el-button {
        width: 76px;
        left: -4px;
        position: relative;
        z-index: 2;
    }
}

.custom-tree-node {
  font-size: 14px;
  min-width: 200px;
}

.pop-menu > div {
  padding: 10px;
  cursor: pointer;
}

.pop-menu > div:hover {
  background: #eee;
}

.pop-menu > div > span {
  margin-right: 10px;
}
.wrap {
  margin: 30px;
}
.operate-btn {
  line-height: 1;
  display: inline-block;
  padding: 0 10px;
  color: #5074ee;
  &:first-child {
    border-right: 1px solid #ccc;
  }
}
.el-checkbox-group {
  flex-direction: column;
  display: flex;
}
.dialog-content {
  font-size: 16px;
  color: #292929;
  padding: 15px 0 40px;
  text-align: center;
  border-bottom: 1px solid #e6e6e6;
}
</style>
